.org-info{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
}

.org-info-description{
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: smaller;
  
  h4{
    text-transform: uppercase;
  }

  p{
    color: #29292bca;
    font-size: smaller;
  }
}