.header {
  width: 100%;
  min-height: 80px;
  top: 0;
  position: fixed;
  margin: 0px auto;
  text-transform: capitalize;
  box-sizing: border-box;
  background-color: white;
  transition: all 0.3s ease-in-out;
  z-index: 3 !important;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.5);

  // li hr {
  //   display: none;
  // }
  
  // li.active a hr {
  //   display: block;
  //   width: 25px;
  //   height: 2px;
  //   margin: 0 auto;
  //   border: none;
  //   border-radius: 100px;
  //   background-color: #d94505;
  //   margin-top: 2px;
  //   animation: width_change 0.3s ease-in-out;
  //   animation-fill-mode: forwards;
  // }

  li.active a{
    color: #d94505;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
  
  li a {
    width: 100%;
    text-align: center;
    display: block;
    padding: 15px 20px;
    margin-left: 15px;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 18px;
    color: #415365;
    font-weight: normal;
    border: none;
    transition: all 0.3s ease-out;
    user-select: none;
  }

  li a:hover {
    transform: scale(1.1);
    color: #d94505;
  }

  .navContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .information_wrapper{  
    width: 100%;
    height: 100% !important;
    padding: 10px 20px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 20px;
    background-color: #060b34cc;
    color: white;
    font-size: 12px;
    font-weight: 500;
    gap: 50px;
  }

  .linkWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
  }

  .menu {
    clear: both;
    max-height: 0;
    align-self: center;
  }

  /* menu icon */
 .menu-icon {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: 0;
    user-select: none;
    transition: all 0.3s ease;
    top: 5px;
    transform: translateY(30%);
  }

  .navIcon-container{
    padding: 20px 10px;
    margin-right: 10px;
  }

  .menu-icon .navicon {
    background: black;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .menu-icon .navicon:before,
  .menu-icon .navicon:after {
    background: black;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .menu-icon .navicon:before {
    top: 5px;
  }

  .menu-icon .navicon:after {
    top: -5px;
  }

  .menu-icon ~  .navContainer .linkWrapper .menu {
    transition: all 0.2s ease-out;
  }

  /* menu btn */
  .menu-icon.active ~ .navContainer .linkWrapper .menu {
    max-height: 500px !important;
    background-color: initial;
    transition: all 0.3s ease-in-out;
    padding-top: 20px;
    animation: opacity_change 0.2s ease-in;
  }

  .menu-icon.active .navicon {
    background: transparent;
  }

  .menu-icon.active .navicon:before {
    transform: rotate(-45deg);
    background-color: #060b34cc;
  }

  .menu-icon.active .navicon:after {
    transform: rotate(45deg);
    background-color: #060b34cc;
  }

  .menu-icon.active:not(.steps) .navicon:before,
  .menu-icon.active:not(.steps) .navicon:after {
    top: 0;
  }

  .logoContainer {
    width: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #fff;
    z-index: 1;
  }

  .logoContainer::after{
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    right: -30px;
    transform: skewX(25deg);
    box-shadow: 5px 0 5px rgba(0,0,0,0.1);
  }

  .logoContainer>img {
    object-fit: scale-down;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    width: 200px;
    margin-top: 0px;
    cursor: pointer;
  }


  .information_wrapper > .org-info, .call-wrapper > .org-info{
    width: fit-content !important;
    display: flex;
    flex-direction: row;
    align-items: center !important;

    a {
      color: #fff;
      font-weight: 500;
      font-size: 15px;
      text-decoration: underline;
    }
  }

  .actionBtn{
    // border: 2px solid #d94505 !important;
    // color: black !important;
    background-color: #d94505 !important;
    color: white !important;
    border: none !important;

    text-transform: capitalize;
    padding: 5px 20px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .linkWrapper .actionBtn{
    margin-left: 20px;
  }
}


@media (min-width: 67em) {
  .header {
    display: flex;
  }

  .header li {
    float: left;
  }

  .header .menu {
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}

@media only screen and (max-width: 67em) {
  .header .logoContainer{
    width: fit-content;
    margin-top: 10px;
    display: block;
    margin-left: 10px;
  } 

  li a{
    margin-left: 0px !important;
  }

  .linkWrapper{
    margin-right: 0px !important;
  }

  .linkWrapper .actionBtn{
    display: none;
  }

  .menu {
    width: 100%;
  }

  .header .information_wrapper{
    display: none;
  }

  .logoContainer::after{
    display: none;
  }
}