.footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 275px; 
  border-top: 1px solid black;
  padding: 20px;
  justify-content:space-evenly;
  gap: 30px;
}

.information-container { 
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 0px;

  .location-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

 img {
  width: 250px;
 }

 a {
  text-decoration: none;
  color: rgb(55, 55, 198);
  font-weight: 500;
 }

 a:hover{
  text-decoration: underline;
 }

 p{
  font-weight: 500;
  font-size: small !important;
 }
}

.section-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: smaller;
  padding: 15px 0px;

  h4{ 
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: rgb(61, 60, 60);
    font-weight: 500;
   }
  
   a:hover{
    text-decoration: underline;
   }
}

@media only screen and (max-width: 1380px) {
  .footer {
    justify-content:space-between;
  } 
}
@media only screen and (max-width: 1200px) {
  .footer {
    justify-content:space-evenly;
  } 
}

@media only screen and (max-width: 450px) {
  .footer {
    justify-content:left;
  } 
}