*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 110px;
}

.pageContainer{
  display: flex;
  flex-direction: column;
}

.pageContainer > :nth-child(2){
  margin-top: 110px; 
  min-height: calc(100vh - 36vh);
}


input:invalid[focused="true"]~.errorMessage,
textarea:invalid[focused="true"]~.errorMessage{
  position: inherit !important;
  z-index: 0 !important;
  animation: top_slide 0.7s;
  animation-fill-mode: forwards;
  opacity: 1 !important;
}

.errorMessage {
  position: absolute;
  color: #c54d4f;
  opacity: 0 !important;
  z-index: -1 !important;
  margin-top: 5px;
  transition: all 0.3s ease-in-out !important;
  padding: 5px 10px !important;
  animation-fill-mode: forwards;
}


.password-field{
  width: 100%;
  position: relative;

  input{
      padding-right: 45px;
  }

  .password-icon{
      width: 30px;
      height: 30px;
      position: absolute;
      top: 29px;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: transparent;
      color: #343c7f;
  }
}

.ag-theme-alpine {
  width: 100%;
  height: 100%; 
  margin-top: 10px;
  // --ag-foreground-color: #d8d8d8; 
  // --ag-background-color: #cfcfcf;
  // --ag-secondary-background-color: #ff0000;
  // --ag-odd-row-background-color: #e7e7e7;
  // --ag-header-background-color: #494949f1;
  // --ag-header-foreground-color: #ecebebf1;
}

.loading_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(26 26 26 / 88%);
  position: fixed;
  z-index: 9; 
  overflow: hidden !important;
}

@keyframes width_change {
  0% {
    width: 0px;
  }

  100% {
    width: 25px;
  }
}

@media screen and (max-width: 67em) {
  .pageContainer > :nth-child(2){
    margin-top: 80px; 
  }

  * {
    scroll-padding-top: 80px;
  }
}

.popup-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(26 26 26 / 88%);
  position: fixed;
  z-index: 4; 
  overflow: hidden !important;
}
.model_container{
  width: 500px;
  height: min-content;
  background-color: white;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
}

.button_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  Button {
    width: 100%;
    text-transform: capitalize;
    margin: 1rem 0;
    margin-top: 0.1rem;
  }
}

.PhoneInputCountry{
  display: none !important;
}

.input-group{
  position: relative;
  width: 100%;
  height: 100%;
}

.dollarSign{
  position: absolute;
  top: calc(50% - 5px);
  left: 10px;
  z-index: 1;
  transform: translateY(-50%);
  color: #343c7f;
}

.input-group > input:nth-last-child(1){
  padding-left: 25px !important;
}