.form-container{
    width: 75vw;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    .form-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;

      #additional_buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 0px;
        cursor: pointer;
        background-color: #d94505;
        border-radius: 5px;
        padding: 5px 10px;
        user-select: none;
        text-transform: none !important;
        color: white;
      }
      
      #additional_buttons:hover {
        background-color: #7e5820b6 !important;
        color: rgb(191, 191, 191);
      }

      #addButton {
        margin-right: 10px;
        /* margin-left: 10px; */
        width: 30px;
        height: 30px;
      }
      
    }

    .form-section{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between start;
      gap: 30px;
    }

    .form-input{
      display: flex;
      flex-direction: column;
      gap: 5px;

      .form-file-input{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
  
      input[type = "file"]{
        display: none;
      }

      input, select {
        width: calc(100vw * 0.23);
        height: 40px;
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0 10px;
        margin-bottom: 10px;
        font-size: medium;
      }
    }
}


// Styling for src/Pages/Dashboard/AdminDashboard.js
.adminActionsContainer {
  Button {
    text-transform: capitalize !important;
  }
}

.custom-file-button{
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  border: none;
  padding: 8px 12px;
  font-size: medium;
  background-color: #3498db;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.taxApplicationContainer,.mfaContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;

  h2 {
    margin-bottom: 15px;
  }

  .selectTaxYearContainer{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin: 10px 0;

    select {
      width: 150px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 0 10px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.mfaContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  button {
    width: fit-content;
    height: 30px;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    text-transform: capitalize !important;
    padding: 20px 15px;
    margin-top: 10px;
  }
}

.referralContainer{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;
  align-items: center;

  .referralActions{
    flex: 0.5;

    h2, p {
      margin-bottom: 15px;

      span {
        color: #d94505;
      }
    }
  }

  .referralBanner{
    flex: 0.5;
    
    img {
      width: 100%;
      height: 250px;
      // object-fit: contain;
      border-radius: 5px;
    }
  }
}

.referralCodeCopyContainer{
  display: flex;
  width: calc(100vw * 0.20);
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  margin-top: 30px;
  padding: 5px 15px;
  justify-content: space-between;
  gap: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ededed82;
  cursor: pointer;

  p{
    margin-bottom: 0px !important;
  }

  input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-size: 12px;
  }
}

.personalApplicationContainer{
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 200px;
  margin: 10px 0px;
  padding: 5px 20px;
  justify-content: space-evenly;
  border-radius: 5px;
  border: 1.5px solid #ccc;
  // box-shadow: 0 0 10px rgba(0,0,0,0.2);
  // background-color: #e2e2e257;

  h3 {
    margin-bottom: 10px;
  }
  
  button {
    width: fit-content;
    height: 30px;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    text-transform: capitalize !important;
    padding: 20px 15px;
  }
}

// AdminDashboard.js
.filter-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  .filter-action-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    
    .filter-item{
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
  
      label{
        font-size: 14px;
        font-weight: 600;
      }
  
      input, select {
        width: 150px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0 10px;
        font-size: 12px;
        cursor: pointer;
      }

      input{
        width: 100px;
      }
    }
  }



  .button_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px !important;

    Button {
      width: fit-content;
      margin-bottom: 0px;
    }
  }
}