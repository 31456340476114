.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginCard{
        width: 100%;
        max-width: 500px;
        height: fit-content;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .signIn-container{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            h1 {
                font-size: 2rem;
                font-weight: 500;
                color: #343c7f;
                text-align: center;
                user-select: none;
            }

            p {
                font-size: 0.875rem;
                font-weight: 400;
                text-align: center;
                user-select: none;
            }

            .signIn-header{
                font-size: 1.5rem;
                font-weight: 500;
                color: #343c7f;
                text-align: center;
                user-select: none;
            }

            .signIn-form ,.signUp-form{
                display: flex;
                flex-direction: column;
                gap: 1em;
                margin-top: 1em;

                input, textarea {
                    margin-top: 10px;
                    width: 100%;
                    border: 1px solid #343c7f;
                    border-radius: 5px;
                    padding: 10px;
                    outline: none;
                    font-size: 0.875rem;
                }

                .signIn-forgotPassword{
                    text-align: right;
                    font-size: 0.875rem;
                    color: #343c7f;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        text-decoration: underline;
                    }
                }

                .signIn-actionBtn{
                    button{
                        text-transform: capitalize !important;
                        margin-bottom: 20px;
                        width: 100%;
                        border: none;
                        border-radius: 5px;
                        padding: 10px;
                        outline: none;
                        font-size: 0.875rem;
                        background-color: #343c7f;
                        color: #fff;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;

                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;

                        height: 50px;
                    }

                    button > span {
                        align-items: center;
                        justify-content: center;
                    }


                    button:nth-child(2){
                        background-color: transparent;
                        color: #343c7f;
                        margin-bottom: 0;
                    }
                }
            }
            
            .alert-message {
                margin-top: 10px;
            }
        }
        .needToRegister{
            margin-top: 20px;
            font-size: 0.875rem;
            color: #343c7f;
            text-align: center;
            transition: all 0.3s ease-in-out;
        }
    }
}