.model_container{
  h2{
    text-align: center;
  }

  .input_container{
    display: flex;
    flex-direction: column;
    gap: 5px;

    input {
      width: 100%;
      height: 40px;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      font-size: 13px;
      margin: 10px 0;
    }

    select {
      width: 100%;
      height: 30px;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      font-size: 13px;
    }

    textarea {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      resize: none;
      font-size: 13px;
    }
  }
}

.verification-code{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;

  input {
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    font-size: 13px;
    text-align: center;
  }
}