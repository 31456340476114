.bg-rust {
  color: #000000dd;
  background-image: url(../../../public/images/rust-pattern.png);
}

.bg-secondary {
  color: #fff;
  background-color: #f41f1f !important;
}

.bg-maps {
  background-image: url(../../../public/images/map-bg.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
}

.bg-contact{
  background-image: url(../../../public/images/man-on-desk.jpg);
  background-size: cover contain;
  background-position: center;
  background-repeat: repeat-x;
  background-color: #464c88;
}

.bg-hexagon{
  background-image: url("../../../public/images/hexagon-pattern.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-container{
  width: 100%;
  height: calc(100vh - 30vh);
  position: relative;
  display: flex;
  flex-direction: column;
}

.whyUs-container,.ourServices-container,.contactUs-container, .aboutUs-container, .referUs-container,.testimonial-container, .org-location{
  width: 80%;
  margin: 0 auto;
  padding: 30px 30px;
  position: relative;
  margin-top: 2em;
  margin-bottom: 2em;
}

.aboutUs-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}

.aboutUs-img{
  margin-left: 2em;
  img {
    object-fit: cover;
  }
}

.aboutUs-message{
  margin-top: 20px;
  p {
    line-height: 1.7;
  }
}

.section-header{
  font-size: 1.125rem;
  font-weight: 500;
  color: #343c7f;
  padding-left: 5rem;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.section-header::before, .section-header::after{
  content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    height: 4px;
    width: 1.5rem;
    background-color: #343c7f;
    border-radius: 5rem;
    opacity: 0.5;
}

.section-header::after{
  content: "";
  position: absolute;
  left: 1.75rem;
  width: 2.5rem;
}

.service-container{ 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
}

.service, .provideService{
  width: 48%;
  height: 20em;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover{
    transform: scale(1.02);
  }

  img{
    width: 100%;
    height: 70%;
    object-fit: cover;
    user-select: none;
  }

  h3{
    font-size: 1.125rem;
    font-weight: 500;
    color: #343c7f;
    text-align: center;
  }

  p{
    font-size: 0.875rem;
    font-weight: 400;
    color: #343c7f;
    text-align: center;
  }
}

.provideService{
  width: 32%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.landingImage-container{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .landing-message{
    background-color: #343b7fe2 !important;
    width: 50em;
    position: absolute;
    display: flex;
    flex-direction: column;
    color: white;
    left: calc(100% - 50em);
    top: auto;
    bottom: 20%;
    padding: 20px;
    border-radius: 5px;


    h1 {
      font-weight: 700;
      font-size: 2rem;
    }
  }
}

.landing-container > * .swiper-pagination-bullet {
  background-color: #fff !important;
}

.testimonial-container > * .swiper-pagination-bullet {
  background-color: #195576 !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-weight: 900;
  color: #fff !important;
}  

.provideServiceBtn{
  background-color: #195576 !important;
  color: white !important;
  border: none !important;
  text-transform: capitalize !important;
  padding: 5px 20px;
  border-radius: 5px;
  margin-top: 1em;
}

.aboutUs-stats{
  display: flex;
  flex-direction: row;
  margin-top: 3.5em;
  justify-content: space-evenly;
  // gap: 2em;
}

.stats{
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    width: 100%;
    height: 90px;
    object-fit: cover;
  }

  h3{
    font-weight: bold;
    text-align: center;
    margin-top: -3.5em;
    margin-bottom: 2.5em;
  }

  p{
    font-size: 0.95rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  
  }
}

.cta-section{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.sectioned-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1
}

.cta-section:before, .cta-section:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background: url(../../../public/images/lines-left.png) no-repeat 0 0;
  background-blend-mode:darken;
  z-index: -1;
}

.cta-section:after {
  right: 0;
  left: auto;
  transform: rotate(180deg);
}

.cta-section-content{
  min-height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  flex-wrap: wrap;
  gap: 2em;

  h1{
    flex: 0.55;
    color: inherit;
    font-weight: 500;
    text-align: center;
    text-align: left;
  }
}

.cta-section-information{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: inherit;
  gap: 1em;

  .org-info{
    align-items: center;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  .org-info-description{
    a, p {
      color: inherit;
      text-decoration: none;
      font-size: medium;
    }
  }
}

.testimonial-card{
  width: calc(100% - 40px);
  height: 300px;
  margin: 0 auto;
  background-color: #f0f0f08e;
  margin-top: 20px;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: grab;

  p{
    max-width: 80%;
    font-size: 0.875rem;
    font-weight: 400;
    color: #343c7f;
    text-align: center;
    user-select: none;
  }

  h3{
    font-size: 1.125rem;
    font-weight: 500;
    color: #343c7f;
    text-align: center;
    margin-top: 20px;
    user-select: none;
  }
}

.contactUs-container{
  margin-bottom: 0 !important;
}


.contactUs-container > .section-header {
  color: #fff;
}

.contactUs-container > .section-header::before, .contactUs-container > .section-header::after{
  background-color: #fff !important;
}

.contactUs-container {
  .contactUs-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;

    input, textarea {
      margin-top: 10px;
      width: 100%;
      border: none;
      border-radius: 5px;
      padding: 10px;
      outline: none;
      font-size: 0.875rem;
      font-weight: 400;
      color: #fff;
      background-color: #ffffff26;
      border: 1px solid #ffffff80;

      &::placeholder {
        color: #ffffffc0;
      }
    }

    textarea {
      resize: none;
      height: 150px;
      line-height: 1.5;
    }

    button {
      width: fit-content;
      background-color: #d94505 !important;
      color: white !important;
      border: none !important;
      text-transform: capitalize !important;
      padding: 7px 30px;
      border-radius: 5px;
      margin-top: 1em;
    }
  }
}

@media only screen and (max-width: 1230px)  {
  .whyUs-container,.ourServices-container,.contactUs-container, .aboutUs-container, .referUs-container,.testimonial-container, .org-location{
    width: 90%;
  }
}

@media only screen and (max-width: 1040px){
  .ourServices-container > .service-container > .service{
    width: 47% !important;
  }
}

@media only screen and (max-width: 970px){
  .aboutUs-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .aboutUs-img {
    margin-left: 0;
    margin-top: 3em;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 920px){
  .whyUs-container,.ourServices-container,.contactUs-container, .aboutUs-container, .referUs-container,.testimonial-container, .org-location{
    width: 100%;
  }

  #additionalInfo{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 3em 
  }

  .cta-section:before, .cta-section:after {
    background: none;
  }
}

@media only screen and (max-width: 710px) {
  .service-container { 
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 2em;

    .provideService{
      width: 100%;
    }
  }

  .ourServices-container > .service-container > .service{
    width: 100% !important;
  }

  #additionalInfo{
    padding: 1em;
  }
}